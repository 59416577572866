import React from 'react';
import { Helmet } from 'react-helmet';
import DebtCard from '../../components/DebtCard/DebtCard';
import withPrivateRoute from '../../hocs/withPrivateRoute';
import { Roles } from '../../utils/constants';

const Payment: React.FC = () => {
	return (
		<>
			<Helmet>
				<title>Payment</title>
			</Helmet>
			<div className="page">
				<h1 className="page__title">Payment</h1>
				<DebtCard />
			</div>
		</>
	);
};

export default withPrivateRoute(Payment, [Roles.admin, Roles.user]);
