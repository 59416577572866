import { Card } from 'antd';
import Loader from '../../components/Loader/Loader';
import React, { useState, useContext, useEffect } from 'react';
import { PaymentsApi } from '../../services/api/Payments';
import { pricesStoreContext } from '../../stores/PricesStore';
import {addressGeneration, getPrices} from "../../services/cryptoPayment";
import QRCode from '../QRCode/QRCode';
import { userStoreContext } from '../../stores/UserStore';
import { useQueryParam } from '../../utils/queryParams';


import './style.scss';
import { useCallback } from 'react';

const DebtCard = () => {
	const priceId = useQueryParam('priceId');
	const packName = useQueryParam('packName');
	const currencies: { crypto: string, exchange: string}[] = [
		{crypto: 'BTC', exchange: 'BTCEUR'}, 
		{crypto: 'ETH', exchange: 'ETHEUR'}, 
		{crypto: 'USDT', exchange: 'EURUSDT'}
	];
	const { user } = useContext(userStoreContext);
	const { fetchPrices, prices } = useContext(pricesStoreContext);

	const [loading, setLoading] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState(false);
	const [amountCrypto, setAmountCrypto] = useState<number>(0);
	const [paymentUrl, setpaymentUrl] = useState<string>('');
	const [transactionId, settransactionId] = useState<string>('');
	
	const [currentCurency, setcurrentCurency] = useState<string>('BTC');
	const [currentCurencyPair, setcurrentCurencyPair] = useState<string>('BTCEUR');
	const [currentAddress, setcurrentAddress] = useState<string>('');

	useEffect(() => {
		if (!priceId) return;	

		setIsLoading(true);
		fetchPrices().finally(() => {	
			PaymentsApi.create({ priceId })
			.then(({ amountBTC, paymentUrl, transactionId }) => {
				settransactionId(transactionId);
				prepareAddress({crypto: 'BTC', exchange: 'BTCEUR'}, transactionId);
			})
			.finally(() => {
				setLoading(false);
			});			
		});	
	}, [priceId]);

	const prepareAddress = useCallback((curency, transactionId)=>{
		setLoading(true);
		addressGeneration(transactionId, curency.crypto).then((res)=>{						
			setcurrentCurency(res?.data?.data?.currency);
			setcurrentAddress(res?.data?.data?.address);
			setcurrentCurencyPair(curency.exchange);

			getPrices(curency.exchange).then((data) => {
				setLoading(false);
				setIsLoading(false);
				let curPrice = data.data.price;
				if (curency.exchange.indexOf('EUR') == 0) {
					curPrice = 1.0 / curPrice;
				}
				prices.map((priceData, index) => {
					if(priceId == priceData.id){
						const price = priceData.costTip * priceData.countPaidTips / curPrice;//currentCurencyPair.indexOf('EUR') === 0 ? data.data.price /(priceData.costTip * priceData.countPaidTips) : (priceData.costTip * priceData.countPaidTips / data.data.price);
						setAmountCrypto(price)
					}
						
				})											
			}).catch((err)=>{
				setLoading(false);
				if(err?.response?.status === 401){					
				}			
			});									
		}).catch((err)=>{
			setLoading(false);
			if(err?.response?.status === 401){

			}			
		});
	},[priceId]);

	return isLoading ? (
		<Loader />
	) : ([
		<div>{
			currencies.map((curency, index) => {
				return <div key={index} className={'crypto-currency' + (curency.crypto == currentCurency ? ' active' : '')}
				onClick={() => {
					prepareAddress(curency, transactionId)
				}}>
				 {curency.crypto}	
				</div>
			})
		}
		</div>,
		<Card loading={loading} title={`Purchase package "${packName}"`} bordered={true} className="debt-card">
			<div className="debt-card__currency">
				<span><b>{currentCurency}</b></span>
			</div>

			<div className="debt-card__amount">{amountCrypto}</div>
			<div className="debt-card__btc-wrapper">
				{/* <QRCode paymentUrl={paymentUrl} /> */}
				<div>
					Copy this <br/> <b>{currentAddress} </b><br/>address:
					<br />
					<p className="font-bold">{paymentUrl}</p>
				</div>
			</div>
			<div className="mt-5">
				Please be careful to send only to this address. Sending any other currency may result in a
				deposit delay or fund being lost.
			</div>
		</Card>]
	);
};

export default DebtCard;
